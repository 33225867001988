import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const checkValidToken = () => {
    const hasToken = localStorage.getItem("has_token");
    if (!hasToken) {
      return false;
    }
    return true;
  };

  return (
    <Fragment>
      {checkValidToken() ? (
        <Route
          {...rest}
          render={(props) => <Component {...rest} {...props} />}
        />
      ) : (
        <Redirect to="/login" />
      )}
    </Fragment>
  );
};

export default ProtectedRoute;
