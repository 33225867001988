const getLotSize = (strike) => {
  if (strike.indexOf("BANKNIFTY") >= 0) {
    return 15;
  } else if (strike.indexOf("FINNIFTY") >= 0) {
    return 40;
  } else {
    return 50;
  }
};

const NseCD = 3,
  BseCD = 6,
  Indices = 9;

const modeFull = "full", // Full quote including market depth. 164 bytes.
  modeQuote = "quote", // Quote excluding market depth. 52 bytes.
  modeLTP = "ltp";

const parseBinary = (binpacks) => {
  const packets = _splitPackets(binpacks),
    ticks = [];

  for (let n = 0; n < packets.length; n++) {
    const bin = packets[n],
      instrument_token = _buf2long(bin.slice(0, 4)),
      segment = instrument_token & 0xff;

    let tradable = true;
    if (segment === Indices) tradable = false;

    // Add price divisor based on segment
    let divisor = 100.0;
    if (segment === NseCD) {
      divisor = 10000000.0;
    } else if (segment === BseCD) {
      divisor = 10000.0;
    }

    // Parse LTP
    if (bin.byteLength === 8) {
      ticks.push({
        tradable: tradable,
        mode: modeLTP,
        instrument_token: instrument_token,
        last_price: _buf2long(bin.slice(4, 8)) / divisor,
      });
      // Parse indices quote and full mode
    } else if (bin.byteLength === 28 || bin.byteLength === 32) {
      let mode = modeQuote;
      if (bin.byteLength === 32) mode = modeFull;

      const tick = {
        tradable: tradable,
        mode: mode,
        instrument_token: instrument_token,
        last_price: _buf2long(bin.slice(4, 8)) / divisor,
        ohlc: {
          high: _buf2long(bin.slice(8, 12)) / divisor,
          low: _buf2long(bin.slice(12, 16)) / divisor,
          open: _buf2long(bin.slice(16, 20)) / divisor,
          close: _buf2long(bin.slice(20, 24)) / divisor,
        },
        change: _buf2long(bin.slice(24, 28)),
      };

      // Compute the change price using close price and last price
      if (tick.ohlc.close !== 0) {
        tick.change =
          ((tick.last_price - tick.ohlc.close) * 100) / tick.ohlc.close;
      }

      // Full mode with timestamp in seconds
      if (bin.byteLength === 32) {
        tick.exchange_timestamp = null;
        const timestamp = _buf2long(bin.slice(28, 32));
        if (timestamp) tick.exchange_timestamp = new Date(timestamp * 1000);
      }

      ticks.push(tick);
    } else if (bin.byteLength === 44 || bin.byteLength === 184) {
      let mode = modeQuote;
      if (bin.byteLength === 184) mode = modeFull;

      const tick = {
        tradable: tradable,
        mode: mode,
        instrument_token: instrument_token,
        last_price: _buf2long(bin.slice(4, 8)) / divisor,
        last_traded_quantity: _buf2long(bin.slice(8, 12)),
        average_traded_price: _buf2long(bin.slice(12, 16)) / divisor,
        volume_traded: _buf2long(bin.slice(16, 20)),
        total_buy_quantity: _buf2long(bin.slice(20, 24)),
        total_sell_quantity: _buf2long(bin.slice(24, 28)),
        ohlc: {
          open: _buf2long(bin.slice(28, 32)) / divisor,
          high: _buf2long(bin.slice(32, 36)) / divisor,
          low: _buf2long(bin.slice(36, 40)) / divisor,
          close: _buf2long(bin.slice(40, 44)) / divisor,
        },
      };

      // Compute the change price using close price and last price
      if (tick.ohlc.close !== 0) {
        tick.change =
          ((tick.last_price - tick.ohlc.close) * 100) / tick.ohlc.close;
      }

      // Parse full mode
      if (bin.byteLength === 184) {
        // Parse last trade time
        tick.last_trade_time = null;
        const last_trade_time = _buf2long(bin.slice(44, 48));
        if (last_trade_time)
          tick.last_trade_time = new Date(last_trade_time * 1000);

        // Parse timestamp
        tick.exchange_timestamp = null;
        const timestamp = _buf2long(bin.slice(60, 64));
        if (timestamp) tick.exchange_timestamp = new Date(timestamp * 1000);

        // Parse OI
        tick.oi = _buf2long(bin.slice(48, 52));
        tick.oi_day_high = _buf2long(bin.slice(52, 56));
        tick.oi_day_low = _buf2long(bin.slice(56, 60));
        tick.depth = {
          buy: [],
          sell: [],
        };

        let s = 0,
          depth = bin.slice(64, 184);
        for (let i = 0; i < 10; i++) {
          s = i * 12;
          tick.depth[i < 5 ? "buy" : "sell"].push({
            quantity: _buf2long(depth.slice(s, s + 4)),
            price: _buf2long(depth.slice(s + 4, s + 8)) / divisor,
            orders: _buf2long(depth.slice(s + 8, s + 10)),
          });
        }
      }

      ticks.push(tick);
    }
  }

  return ticks;
};

// split one long binary message into individual tick packets
const _splitPackets = (bin) => {
  // number of packets
  let num = _buf2long(bin.slice(0, 2)),
    j = 2,
    packets = [];

  for (let i = 0; i < num; i++) {
    // first two bytes is the packet length
    const size = _buf2long(bin.slice(j, j + 2)),
      packet = bin.slice(j + 2, j + 2 + size);

    packets.push(packet);

    j += 2 + size;
  }

  return packets;
};

const _buf2long = (buf) => {
  let b = new Uint8Array(buf),
    val = 0,
    len = b.length;

  for (let i = 0, j = len - 1; i < len; i++, j--) {
    val += b[j] << (i * 8);
  }

  return val;
};

export { getLotSize, parseBinary };
