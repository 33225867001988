import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import styles from "../styles.module.css";

const Orders = ({ state, exitOrder, isExitingOrder }) => {
  return (
    <>
      <h2 style={{ fontSize: "1.25rem" }}>Orders</h2>
      <Table striped bordered hover size="sm" style={{ fontSize: ".85rem" }}>
        <thead>
          <tr>
            {/* <th>#</th> */}
            <th>Instrument</th>
            <th>Type</th>
            <th>Qty.</th>
            <th style={{ textAlign: "right" }}>Avg.</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {state.orders &&
            state.orders?.length > 0 &&
            state.orders?.map((item) => (
              <tr key={`order-${item.order_id}`}>
                {/* <td>{item.order_id}</td> */}
                <td>{item.tradingsymbol}</td>
                <td>{item.transaction_type}</td>
                <td>{item.quantity}</td>
                <td style={{ textAlign: "right" }}>
                  {item.status === "COMPLETE" && (
                    <span>{Number(item.average_price).toFixed(2)}</span>
                  )}
                  {(item.status === "REJECTED" ||
                    item.status === "CANCELLED") && <span>{item.price}</span>}
                </td>
                <td>
                  <span
                    className={
                      item.average_price > 0
                        ? styles.buyColor
                        : styles.sellColor
                    }
                  >
                    {item.status}
                  </span>
                </td>
                <td>
                  &nbsp;
                  {item.status === "OPEN" && (
                    <Button
                      variant="link"
                      size="sm"
                      style={{ padding: 0 }}
                      onClick={() => {
                        exitOrder(item);
                      }}
                      disabled={isExitingOrder}
                    >
                      Exit
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          {state.orders?.length <= 0 && (
            <tr>
              <td colSpan={6}>No Orders Available</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Orders;
