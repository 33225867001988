import React, { useReducer } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Header from "../shared/Header";
import GetQuote from "../shared/GetQuote";
import Orders from "../shared/Orders";
import Positions from "../shared/Positions";
import reducer from "./reducer";
import { getLotSize } from "./utils";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";

function NFO() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, {
    call_strike: localStorage.getItem("call_strike") || "",
    call_type: "Market",
    call_qty: 0,
    call_lot_size: 50,
    call_sell_point: 2,
    call_quote: null,
    call_active: null,
    call_margin_req: 0,
    put_strike: localStorage.getItem("put_strike") || "",
    put_type: "Market",
    put_qty: 0,
    put_lot_size: 50,
    put_sell_point: 2,
    put_quote: null,
    put_active: null,
    put_margin_req: 0,
    positions: [],
    positions_pl: 0,
    orders: [],
    polling_for_quotes: false,
    // key: null,
    // token: null,
  });

  // const webSocket = useMemo(() => {
  //   console.log("WS:// try to initiate with ", state.key, state.token);
  //   if (state.key && state.token) {
  //     console.log("WS:// opening socket");
  //     const webSock = new WebSocket(
  //       `wss://ws.kite.trade?api_key=${state.key}&access_token=${state.token}`
  //     );
  //     webSock.addEventListener("message", (event) => {
  //       console.log("WS:// message recieved raw", event.data);
  //       console.log("WS:// message recieved ", parseBinary(event.data));
  //     });
  //     return webSock;
  //   }
  // }, [state.key, state.token]);

  console.log("STATE ", state);

  // const { refetch: fetchInfo } = useQuery({
  //   queryKey: ["get-info"],
  //   queryFn: async () => {
  //     try {
  //       const { data } = await axios.get("/api/get-info");
  //       if (data) {
  //         console.log("WS:// info received ", data);
  //         dispatch({
  //           type: "info",
  //           value: data.data,
  //         });
  //       }
  //       return data;
  //     } catch (e) {
  //       console.log(e);
  //     }
  //     return [];
  //   },
  // });

  const { refetch: fetchPositions } = useQuery({
    queryKey: ["get-positions"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/get-positions");
        if (data) {
          const { data: positions } = data;
          dispatch({
            type: "positions",
            value: positions,
          });

          if (positions.net && positions.net.length > 0) {
            dispatch({
              type: "positions_pl",
              value: positions.net.reduce((total, item) => {
                return total + item.unrealised;
              }, 0),
            });
          }
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
  });

  const { refetch: fetchOrders } = useQuery({
    queryKey: ["get-orders"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/get-orders");
        if (data) {
          const { data: orders } = data;
          dispatch({
            type: "orders",
            value: orders.reverse(),
          });
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
  });

  const { mutate: placeOrder, isLoading: isPlacingOrder } = useMutation({
    mutationKey: ["place-orders"],
    mutationFn: async (type) => {
      try {
        let body = null;
        if (type === "CALL") {
          body = {
            tradingsymbol: state.call_active.strike,
            qty: state.call_qty,
            sell_point: state.call_sell_point,
            price: state.call_active.last_price,
          };
        } else {
          body = {
            tradingsymbol: state.put_active.strike,
            qty: state.put_qty,
            sell_point: state.put_sell_point,
            price: state.put_active.last_price,
          };
        }
        const { data } = await axios.post("/api/place-order", body);
        if (data) {
          const { data: order } = data;
          if (order && order.order_id) {
            fetchOrders();
            fetchPositions();
          } else if (data.error) {
            toast(data?.error?.message);
          }
        }
        return true;
      } catch (e) {
        console.log("ORDER ERROR /////", e);
        toast(e);
      }
      return [];
    },
    enabled: false,
  });

  const { mutate: exitOrder, isLoading: isExitingOrder } = useMutation({
    mutationKey: ["exit-orders"],
    mutationFn: async (order) => {
      try {
        let body = { order_id: order.order_id };
        // TODO: need to make sure current active items in this trade sylmbol only
        if (order.tradingsymbol.endsWith("CE")) {
          body.price = state.call_active?.last_price;
        } else {
          body.price = state.put_active?.last_price;
        }
        const { data } = await axios.post("/api/exit-order", body);
        if (data) {
          const { data: order } = data;
          if (order.order_id) {
            fetchOrders();
            fetchPositions();
          }
        }
        return true;
      } catch (e) {
        console.log("ORDER ERROR /////", e);
      }
      return [];
    },
    enabled: false,
  });

  const { isLoading: isLoggingOut, refetch: logout } = useQuery({
    queryKey: ["logout"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/logout");
        if (data.logout) {
          localStorage.removeItem("has_token");
          history.push("/");
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    enabled: false,
  });

  const { refetch: fetchQuotes } = useQuery({
    queryKey: ["get-quotes"],
    queryFn: async () => {
      const strike_prices = [];
      if (state.call_strike) {
        strike_prices.push(state.call_strike);
      } else {
        localStorage.setItem("call_strike", "");
      }
      if (state.put_strike) {
        strike_prices.push(state.put_strike);
      } else {
        localStorage.setItem("put_strike", "");
      }
      if (strike_prices.length === 0) {
        dispatch({
          type: "call_active",
          value: null,
        });
        dispatch({
          type: "put_active",
          value: null,
        });
        return null;
      }
      try {
        const { data } = await axios.get("/api/get-quotes", {
          params: {
            strike_prices,
          },
        });
        if (data) {
          console.log(data);
          if (data.data[`NFO:${state.call_strike}`]) {
            const call_quote = data.data[`NFO:${state.call_strike}`];
            localStorage.setItem("call_strike", state.call_strike);
            dispatch({
              type: "call_quote",
              value: call_quote,
            });
            if (call_quote && call_quote.instrument_token) {
              dispatch({
                type: "call_active",
                value: {
                  strike: state.call_strike,
                  last_price: call_quote.last_price,
                  average_price: call_quote.last_price,
                  instrument_token: call_quote.instrument_token,
                },
              });
              dispatch({
                type: "call_lot_size",
                value: getLotSize(state.call_strike),
              });

              const lot_size = getLotSize(state.call_strike);
              if (!state.call_qty || state.call_qty % lot_size !== 0) {
                dispatch({
                  type: "call_qty",
                  value: lot_size,
                });
              }
            } else {
              dispatch({
                type: "call_active",
                value: null,
              });
            }
          } else {
            dispatch({
              type: "call_active",
              value: null,
            });
          }

          if (data.data[`NFO:${state.put_strike}`]) {
            const put_quote = data.data[`NFO:${state.put_strike}`];
            localStorage.setItem("put_strike", state.put_strike);
            dispatch({
              type: "put_quote",
              value: put_quote,
            });
            if (put_quote && put_quote.instrument_token) {
              dispatch({
                type: "put_active",
                value: {
                  strike: state.put_strike,
                  last_price: put_quote.last_price,
                  average_price: put_quote.average_price,
                  instrument_token: put_quote.instrument_token,
                },
              });
              dispatch({
                type: "put_lot_size",
                value: getLotSize(state.put_strike),
              });
              const lot_size = getLotSize(state.put_strike);
              if (!state.put_qty || state.put_qty % lot_size !== 0) {
                dispatch({
                  type: "put_qty",
                  value: lot_size,
                });
              }
            } else {
              dispatch({
                type: "put_active",
                value: null,
              });
            }
          } else {
            dispatch({
              type: "put_active",
              value: null,
            });
          }
          // now we need to refetch
          console.log("polling ", state.polling_for_quotes);
          if (state.polling_for_quotes) {
            setTimeout(() => {
              fetchQuotes();
            }, 1000);
          }
        }
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    enabled: false,
  });

  // const subscribeData = () => {
  //   const instruments = [];
  //   if (state.call_strike) {
  //     instruments.push(Number(state.call_active.instrument_token));
  //   }
  //   if (state.put_strike) {
  //     instruments.push(Number(state.put_active.instrument_token));
  //   }
  //   if (instruments.length > 0) {
  //     console.log("WS:// subscribe ", { a: "subscribe", v: instruments });
  //     webSocket.send(JSON.stringify({ a: "subscribe", v: instruments }));
  //     console.log("WS:// set mode ", { a: "mode", v: ["ltp", instruments] });
  //     webSocket.send(JSON.stringify({ a: "mode", v: ["ltp", instruments] }));
  //   }
  // };

  // const unSubscribeData = () => {
  //   const v = [];
  //   if (state.call_strike) {
  //     v.push(state.call_active.instrument_token);
  //   }
  //   if (state.put_strike) {
  //     v.push(state.put_active.instrument_token);
  //   }
  //   if (v.length > 0) {
  //     console.log("WS:// un-subscribe ", v);
  //     webSocket.send(JSON.stringify({ a: "unsubscribe", v }));
  //   }
  // };

  return (
    <>
      {isLoggingOut && <div>Logging out...</div>}
      <Container fluid>
        <Header logout={logout} />

        <Container fluid>
          <GetQuote
            state={state}
            dispatch={dispatch}
            fetchQuotes={fetchQuotes}
            placeOrder={placeOrder}
            // subscribeData={subscribeData}
            // unSubscribeData={unSubscribeData}
            isPlacingOrder={isPlacingOrder}
          />
          <Row>
            <Col md="6" lg="5">
              <Orders
                state={state}
                exitOrder={exitOrder}
                isExitingOrder={isExitingOrder}
              />
            </Col>
            <Col md="1" lg="1"></Col>
            <Col md="6" lg="5">
              <Positions state={state} />
            </Col>
          </Row>
        </Container>
      </Container>
      <ToastContainer />
    </>
  );
}

export default NFO;
