import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

import ProtectedRoute from "./ProtectedRoute";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import BankNifty from "./BankNifty";
import NFO from "./NFO";
import OptionsV2 from "./NFO/optionsv2";
import Login from "./Login";

const queryClient = new QueryClient({});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <ProtectedRoute exact path="/" component={NFO} />
          <ProtectedRoute exact path="/optionsv2" component={OptionsV2} />
          <ProtectedRoute exact path="/banknifty" component={BankNifty} />
          <Route path="/login" component={Login} />
          <Redirect to="/" />
        </Switch>
      </Router>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
