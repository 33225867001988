import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import styles from "../styles.module.css";

const GetQuote = ({
  state,
  dispatch,
  fetchQuotes,
  placeOrder,
  isPlacingOrder,
  // subscribeData,
  // unSubscribeData,
}) => {
  return (
    <Form>
      <Row style={{ padding: "1rem", backgroundColor: "#efefef" }}>
        <Col xs="4" lg="2">
          <Form.Group className="mb-3">
            <Form.Label className={styles.formLabel}>Call Strike</Form.Label>
            <Form.Control
              type="text"
              id="callStrike"
              style={{ fontSize: ".8rem" }}
              width={100}
              value={state.call_strike}
              onChange={(e) => {
                dispatch({
                  type: "call_strike",
                  value: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col xs="4" lg="2">
          <Form.Group className="mb-3">
            <Form.Label className={styles.formLabel}>Put Strike</Form.Label>
            <Form.Control
              type="text"
              id="put_strike"
              style={{ fontSize: ".8rem" }}
              value={state.put_strike}
              onChange={(e) => {
                dispatch({
                  type: "put_strike",
                  value: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col xs="4" lg="2">
          <Button
            type="button"
            size="sm"
            className={styles.formButton}
            style={{ marginTop: "2.15rem", marginLeft: "2rem" }}
            onClick={async () => {
              if (!state.polling_for_quotes) {
                await dispatch({
                  type: "polling_for_quotes",
                  value: true,
                });
                fetchQuotes();
              } else {
                await dispatch({
                  type: "polling_for_quotes",
                  value: false,
                });
                fetchQuotes();
              }
            }}
          >
            {!state.polling_for_quotes
              ? "Start Getting Quotes"
              : "Stop Polling"}
          </Button>
        </Col>
      </Row>
      {(state.call_active || state.put_active) && (
        <>
          {/* <Row style={{ padding: "0" }}>
            <Col xs="2">
              <Button
                type="button"
                size="sm"
                className={styles.formButton}
                style={{ marginLeft: "1rem", marginTop: "2.15rem" }}
                onClick={() => subscribeData()}
                disabled={isPlacingOrder}
              >
                Sub
              </Button>
            </Col>
            <Col xs="3">
              <Button
                type="button"
                size="sm"
                className={styles.formButton}
                style={{ marginTop: "2.15rem" }}
                onClick={() => unSubscribeData()}
                disabled={isPlacingOrder}
              >
                Un-Sub
              </Button>
            </Col>
          </Row> */}
          <Row style={{ padding: "2rem 1rem 0" }}>
            <Col
              xs="12"
              md="4"
              lg="4"
              className={state.call_active ? styles.show : styles.hidden}
            >
              <Row>
                <Col xs="12">
                  <span style={{ fontSize: "1.25rem" }}>
                    <strong>{state.call_active?.strike} LTP:</strong>{" "}
                    {state.call_active?.last_price}{" "}
                    {state.call_margin_req && (
                      <span style={{ fontSize: "1rem", marginLeft: "1rem" }}>
                        {" "}
                        [Mrg. req: {Math.round(state.call_margin_req)}]{" "}
                        {state.call_active?.average_price}
                      </span>
                    )}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className={styles.formLabel}>Qty</Form.Label>
                    <Form.Select
                      size="sm"
                      id="call_qty"
                      value={state.call_qty}
                      onChange={(e) => {
                        dispatch({
                          type: "call_qty",
                          value: Number.parseInt(e.target.value),
                        });
                      }}
                    >
                      {[
                        state.call_lot_size * 1,
                        state.call_lot_size * 2,
                        state.call_lot_size * 3,
                        state.call_lot_size * 4,
                        state.call_lot_size * 5,
                        state.call_lot_size * 6,
                        state.call_lot_size * 7,
                        state.call_lot_size * 8,
                        state.call_lot_size * 9,
                        state.call_lot_size * 10,
                        state.call_lot_size * 15,
                        state.call_lot_size * 20,
                      ].map((lot) => (
                        <option value={lot} key={`lot-size-${lot}`}>
                          {lot}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Form.Group className="mb-3">
                    <Form.Label className={styles.formLabel}>
                      Sell Point
                    </Form.Label>
                    <Form.Select
                      size="sm"
                      id="call_sell_point"
                      value={state.call_sell_point}
                      onChange={(e) => {
                        dispatch({
                          type: "call_sell_point",
                          value: Number.parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value="2">+2</option>
                      <option value="3">+3</option>
                      <option value="4">+4</option>
                      <option value="5">+5</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs="4">
                  <Button
                    type="button"
                    size="sm"
                    className={styles.formButton}
                    style={{ marginLeft: "1rem", marginTop: "2.15rem" }}
                    onClick={() => placeOrder("CALL")}
                    disabled={isPlacingOrder}
                  >
                    &uarr; Buy Call
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs="12" lg="1">
              &nbsp;
            </Col>
            <Col
              xs="12"
              md="4"
              lg="4"
              className={state.put_active ? styles.show : styles.hidden}
            >
              <Row>
                <Col xs="12">
                  <span style={{ fontSize: "1.25rem" }}>
                    <strong>{state.put_active?.strike} LTP:</strong>{" "}
                    {state.put_active?.last_price}
                    {state.put_margin_req && (
                      <span style={{ fontSize: "1rem", marginLeft: "1rem" }}>
                        {" "}
                        [Mrg. req: {Math.round(state.put_margin_req)}]{" "}
                        {state.put_active?.average_price}
                      </span>
                    )}
                  </span>
                </Col>
              </Row>
              <Row>
                <Row>
                  <Col xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label className={styles.formLabel}>Qty</Form.Label>
                      <Form.Select
                        size="sm"
                        id="put_qty"
                        value={state.put_qty}
                        onChange={(e) => {
                          dispatch({
                            type: "put_qty",
                            value: Number.parseInt(e.target.value),
                          });
                        }}
                      >
                        {[
                          state.put_lot_size * 1,
                          state.put_lot_size * 2,
                          state.put_lot_size * 3,
                          state.put_lot_size * 4,
                          state.put_lot_size * 5,
                          state.put_lot_size * 6,
                          state.put_lot_size * 7,
                          state.put_lot_size * 8,
                          state.put_lot_size * 9,
                          state.put_lot_size * 10,
                          state.put_lot_size * 15,
                          state.put_lot_size * 20,
                        ].map((lot) => (
                          <option value={lot} key={`lot-size-${lot}`}>
                            {lot}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs="4">
                    <Form.Group className="mb-3">
                      <Form.Label className={styles.formLabel}>
                        Sell Point
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        id="put_sell_point"
                        value={state.put_sell_point}
                        onChange={(e) => {
                          dispatch({
                            type: "put_sell_point",
                            value: Number.parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value="2">+2</option>
                        <option value="3">+3</option>
                        <option value="4">+4</option>
                        <option value="5">+5</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs="4">
                    <Button
                      type="submit"
                      size="sm"
                      className={styles.formButton}
                      style={{ marginLeft: "1rem", marginTop: "2rem" }}
                      onClick={() => placeOrder("PUT")}
                      disabled={isPlacingOrder}
                    >
                      &darr; Buy Put
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col
              xs="12"
              lg="2"
              className={state.put_active ? styles.show : styles.hidden}
            ></Col>
          </Row>
        </>
      )}
      <hr />
    </Form>
  );
};
export default GetQuote;
