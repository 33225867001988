import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Header = ({ logout }) => {
  return (
    <Navbar bg="dark" data-bs-theme="dark">
      <Container fluid>
        <Navbar.Brand href="#home">Trade with Konnect</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="/">Options</Nav.Link>
          <Nav.Link href="/optionsv2">Options V2</Nav.Link>
          <Nav.Link href="/banknifty">Bank Nifty</Nav.Link>
        </Nav>
        <Nav className="me-right">
          {/* <Nav.Link href="#reload" onClick={() => reloadInstruments()}>
            Reload Instruments
          </Nav.Link> */}
          <Nav.Link href="#logout" onClick={() => logout()}>
            Logout
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
