import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import styles from "../styles.module.css";

const Header = ({ state }) => {
  return (
    <>
      <Row>
        <Col md="7" lg="8">
          <h2 style={{ fontSize: "1.25rem" }}>Positions</h2>
        </Col>
        <Col md="5" lg="4" style={{ textAlign: "right" }}>
          <strong>Total P&L:</strong>{" "}
          <span
            className={
              state.positions_pl > 0 ? styles.buyColor : styles.sellColor
            }
          >
            {Number(state.positions_pl).toFixed(2)}
          </span>
        </Col>
      </Row>

      <Table striped bordered hover size="sm" style={{ fontSize: ".85rem" }}>
        <thead>
          <tr>
            <th>Instrument</th>
            <th>Type</th>
            <th>Qty.</th>
            <th style={{ textAlign: "right" }}>Avg.</th>
            <th style={{ textAlign: "right" }}>P&L</th>
          </tr>
        </thead>
        <tbody>
          {state.positions?.net &&
            state.positions?.net.map((item) => (
              <tr key={`position-${item.instrument_token}`}>
                <td>{item.tradingsymbol}</td>
                <td>{item.product}</td>
                <td>{item.quantity}</td>
                <td style={{ textAlign: "right" }}>{item.average_price}</td>
                <td style={{ textAlign: "right" }}>
                  <span
                    className={
                      item.unrealised > 0 ? styles.buyColor : styles.sellColor
                    }
                  >
                    {item.unrealised}
                  </span>
                </td>
              </tr>
            ))}
          {/* {state.positions?.day &&
            state.positions?.day.map((item) => (
              <tr key={`position-${item.instrument_token}`}>
                <td>{item.tradingsymbol}</td>
                <td>{item.product}</td>
                <td>{item.quantity}</td>
                <td>{item.average_price}</td>
                <td>
                  <span
                    className={
                      item.unrealised > 0 ? styles.buyColor : styles.sellColor
                    }
                  >
                    {item.unrealised}
                  </span>
                </td>
              </tr>
            ))} */}
          {state.positions && !state.positions?.net?.length && (
            <tr>
              <td colSpan={6}>No Positions Available</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Header;
