import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import styles from "../styles.module.css";

const Instruments = ({ state, dispatch, placeOrder, isPlacingOrder }) => {
  return (
    <>
      <h2 style={{ fontSize: "1.25rem", marginTop: "1rem" }}>
        Instruments{" "}
        <span
          style={{ float: "right", fontSize: "1rem", marginTop: "10px" }}
          className={
            state.positions_pl > 0 ? styles.buyColor : styles.sellColor
          }
        >
          {Number(state.positions_pl).toFixed(2)}
        </span>
      </h2>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Instrument</th>
            <th style={{ width: "85px", fontSize: ".85rem" }}>Qty</th>
            <th style={{ width: "85px", fontSize: ".85rem" }}>S.Pts</th>
            <th style={{ width: "65px", fontSize: ".85rem" }}>Price</th>
            <th style={{ width: "60px", fontSize: ".85rem" }}>Buy</th>
            <th style={{ width: "90px", fontSize: ".85rem" }}>Margin Req.</th>
            <th style={{ width: "90px", fontSize: ".85rem" }}>P&L</th>
            <th style={{ width: "40px", fontSize: ".85rem" }}>Del</th>
          </tr>
        </thead>
        <tbody>
          {state.instruments?.length > 0 &&
            state.instruments.map((instrument) => (
              <tr>
                <td style={{ fontSize: ".85rem" }}>{instrument.strike}</td>
                <td>
                  <Form.Select
                    size="sm"
                    id="qty"
                    value={instrument.qty}
                    style={{ fontSize: ".75rem" }}
                    onChange={(e) => {
                      dispatch({
                        type: "update_qty",
                        value: {
                          strike: instrument.strike,
                          qty: Number.parseInt(e.target.value),
                        },
                      });
                    }}
                  >
                    {[
                      instrument.lot_size * 1,
                      instrument.lot_size * 2,
                      instrument.lot_size * 3,
                      instrument.lot_size * 4,
                      instrument.lot_size * 5,
                      instrument.lot_size * 6,
                      instrument.lot_size * 7,
                      instrument.lot_size * 8,
                      instrument.lot_size * 9,
                      instrument.lot_size * 10,
                      instrument.lot_size * 15,
                      instrument.lot_size * 20,
                    ].map((lot) => (
                      <option value={lot} key={`lot-size-${lot}`}>
                        {lot}
                      </option>
                    ))}
                  </Form.Select>
                </td>
                <td>
                  <Form.Select
                    size="sm"
                    id="sell_point"
                    value={instrument.sell_point}
                    style={{ fontSize: ".75rem" }}
                    onChange={(e) => {
                      dispatch({
                        type: "update_sell_point",
                        value: {
                          strike: instrument.strike,
                          sell_point: Number.parseInt(e.target.value),
                        },
                      });
                    }}
                  >
                    <option value="2">+2</option>
                    <option value="3">+3</option>
                    <option value="4">+4</option>
                    <option value="5">+5</option>
                    <option value="8">+8</option>
                    <option value="10">+10</option>
                    <option value="15">+15</option>
                    <option value="20">+20</option>
                  </Form.Select>
                </td>
                <td style={{ textAlign: "right" }}>
                  {state.instrument_quotes[instrument.strike]?.last_price}
                </td>
                <td>
                  <Button
                    type="button"
                    size="sm"
                    className={styles.formButton}
                    style={{ fontSize: ".7rem", margin: 0 }}
                    onClick={() => {
                      placeOrder({
                        instrument,
                        price:
                          state.instrument_quotes[instrument.strike]
                            ?.last_price,
                      });
                    }}
                    disabled={
                      isPlacingOrder ||
                      !state.instrument_quotes[instrument.strike]
                    }
                  >
                    Buy
                  </Button>
                </td>
                <td style={{ textAlign: "right" }}>
                  {state.instrument_quotes[instrument.strike]?.last_price &&
                    Number(
                      state.instrument_quotes[instrument.strike]?.last_price *
                        instrument.qty
                    ).toFixed(2)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {state.positions_each_pl &&
                    state.positions_each_pl[instrument.strike]}
                </td>
                <td>
                  <Button
                    variant="link"
                    size="sm"
                    style={{ fontSize: ".7rem", margin: 0, padding: 0 }}
                    onClick={() => {
                      dispatch({
                        type: "remove_instrument",
                        value: instrument.strike,
                      });
                    }}
                  >
                    Del
                  </Button>
                </td>
              </tr>
            ))}
          <tr>
            <td>
              <Form.Control
                type="text"
                id="instrument"
                style={{ fontSize: ".75rem", padding: "4px 3px" }}
                width={100}
                value={state.new_instrument}
                onChange={(e) => {
                  dispatch({
                    type: "instrument",
                    value: e.target.value,
                  });
                }}
                onBlur={(e) => {
                  const val = e.target.value.trim();
                  if (val) {
                    dispatch({
                      type: "add_instrument",
                      value: val,
                    });
                  }
                }}
              />
            </td>
            <td colSpan={7}> &larr; Add stike to proceed.</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default Instruments;
