import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

function Login() {
  const history = useHistory();

  const { isLoading, isError, data } = useQuery("check-login", async () => {
    const { data } = await axios.get("/api/check-login");
    if (data.token_exist) {
      localStorage.setItem("has_token", true);
      history.push("/");
    } else {
      localStorage.removeItem("has_token");
    }
    return data;
  });

  return (
    <Container fluid>
      {isLoading && (
        <div
          style={{
            margin: "10% 46%",
            width: "150px",
          }}
        >
          Loading....
        </div>
      )}

      {isError && (
        <div
          style={{
            margin: "10% 46%",
            width: "150px",
          }}
        >
          {isError}
        </div>
      )}

      {data && !data.token_exist && (
        <Button
          variant="primary"
          style={{
            margin: "10% 46%",
            width: "150px",
          }}
          onClick={() => {
            window.location.href = "/konnect-login";
          }}
        >
          Login To Kite
        </Button>
      )}
    </Container>
  );
}

export default Login;
