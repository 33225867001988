import { getLotSize } from "./utils";

const reducer = (state, action) => {
  console.log("DISPATCHED ACTION ", action);
  switch (action.type) {
    case "call_strike":
      return {
        ...state,
        call_strike: action.value,
      };
    case "call_type":
      return {
        ...state,
        call_type: action.value,
      };
    case "call_qty":
      return {
        ...state,
        call_qty: action.value,
        call_margin_req: state.call_active?.last_price
          ? state.call_active?.last_price * action.value
          : 0,
      };
    case "call_lot_size":
      return {
        ...state,
        call_lot_size: action.value,
      };
    case "call_sell_point":
      return {
        ...state,
        call_sell_point: action.value,
      };
    case "call_quote":
      return {
        ...state,
        call_quote: action.value,
      };
    case "call_active":
      return {
        ...state,
        call_active: action.value,
      };
    case "put_strike":
      return {
        ...state,
        put_strike: action.value,
      };
    case "put_type":
      return {
        ...state,
        put_type: action.value,
      };
    case "put_qty":
      return {
        ...state,
        put_qty: action.value,
        put_margin_req: state.put_active?.last_price
          ? state.put_active?.last_price * action.value
          : 0,
      };
    case "put_lot_size":
      return {
        ...state,
        put_lot_size: action.value,
      };
    case "put_sell_point":
      return {
        ...state,
        put_sell_point: action.value,
      };
    case "put_quote":
      return {
        ...state,
        put_quote: action.value,
      };
    case "put_active":
      return {
        ...state,
        put_active: action.value,
      };
    case "positions":
      return {
        ...state,
        positions: action.value,
      };
    case "positions_pl":
      return {
        ...state,
        positions_pl: action.value,
      };
    case "orders":
      return {
        ...state,
        orders: action.value,
      };
    case "polling_for_quotes":
      return {
        ...state,
        polling_for_quotes: action.value,
      };
    case "info":
      return {
        ...state,
        key: action.value.key,
        token: action.value.token,
      };

    case "instrument":
      return {
        ...state,
        new_instrument: action.value,
      };
    case "add_instrument":
      const strike = action.value;
      const newList = [...state.instruments];
      const index = newList.findIndex((item) => {
        return item.strike === strike;
      });
      const isAlreadyExisit = index >= 0;
      if (!isAlreadyExisit) {
        newList.push({
          strike: strike,
          lot_size: getLotSize(strike),
          qty: getLotSize(strike),
          sell_point: 2,
        });
        localStorage.setItem("instruments", JSON.stringify(newList));
        return {
          ...state,
          instruments: newList,
          new_instrument: "",
        };
      }
      return { ...state };
    case "remove_instrument":
      const strikeToRemove = action.value;
      const updatedList = state.instruments.filter(
        (item) => item.strike !== strikeToRemove
      );
      localStorage.setItem("instruments", JSON.stringify(updatedList));
      return {
        ...state,
        instruments: updatedList,
      };

    case "instrument_quotes":
      return {
        ...state,
        instrument_quotes: action.value,
      };
    case "update_qty":
      const newList2 = state.instruments.map((item) => {
        if (action.value.strike === item.strike) {
          return {
            ...item,
            qty: action.value.qty,
          };
        } else {
          return { ...item };
        }
      });
      localStorage.setItem("instruments", JSON.stringify(newList2));
      return {
        ...state,
        instruments: newList2,
      };
    case "update_sell_point":
      const newList3 = state.instruments.map((item) => {
        if (action.value.strike === item.strike) {
          return {
            ...item,
            sell_point: action.value.sell_point,
          };
        } else {
          return { ...item };
        }
      });
      localStorage.setItem("instruments", JSON.stringify(newList3));
      return {
        ...state,
        instruments: newList3,
      };
    case "positions_each_pl":
      return {
        ...state,
        positions_each_pl: action.value,
      };
    default:
      break;
  }
};

export default reducer;
