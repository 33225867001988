import React, { useReducer } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useHistory } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import styles from "./BankNifty.module.css";

const reducer = (state, action) => {
  console.log("DISPATCHED ACTION ", action);
  switch (action.type) {
    case "call_strike":
      return {
        ...state,
        call_strike: action.value,
      };
    case "call_type":
      return {
        ...state,
        call_type: action.value,
      };
    case "call_qty":
      return {
        ...state,
        call_qty: action.value,
      };
    case "call_sell_point":
      return {
        ...state,
        call_sell_point: action.value,
      };
    case "call_quote":
      return {
        ...state,
        call_quote: action.value,
      };
    case "call_active":
      return {
        ...state,
        call_active: action.value,
      };
    case "put_strike":
      return {
        ...state,
        put_strike: action.value,
      };
    case "put_type":
      return {
        ...state,
        put_type: action.value,
      };
    case "put_qty":
      return {
        ...state,
        put_qty: action.value,
      };
    case "put_sell_point":
      return {
        ...state,
        put_sell_point: action.value,
      };
    case "put_quote":
      return {
        ...state,
        put_quote: action.value,
      };
    case "put_active":
      return {
        ...state,
        put_active: action.value,
      };
    case "positions":
      return {
        ...state,
        positions: action.value,
      };
    case "orders":
      return {
        ...state,
        orders: action.value,
      };
    default:
      break;
  }
};

function BankNifty() {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, {
    call_strike: "",
    call_type: "Market",
    call_qty: 25,
    call_sell_point: 2,
    call_quote: null,
    call_active: null,
    put_strike: "",
    put_type: "Market",
    put_qty: 25,
    put_sell_point: 2,
    put_quote: null,
    put_active: null,
    positions: [],
    orders: [],
  });

  console.log("STATE ", state);

  const { refetch: fetchPositions } = useQuery({
    queryKey: ["get-positions"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/get-positions");
        if (data) {
          const { data: positions } = data;
          dispatch({
            type: "positions",
            value: positions,
          });
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
  });

  const { refetch: fetchOrders } = useQuery({
    queryKey: ["get-orders"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/get-orders");
        if (data) {
          const { data: orders } = data;
          dispatch({
            type: "orders",
            value: orders,
          });
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
  });

  const { mutate: placeOrder, isLoading: isPlacingOrder } = useMutation({
    mutationKey: ["place-orders"],
    mutationFn: async (type) => {
      try {
        let body = null;
        if (type === "CALL") {
          body = {
            tradingsymbol: state.call_active.strike,
            qty: state.call_active.qty,
            sell_point: state.call_active.sell_point,
          };
        } else {
          body = {
            tradingsymbol: state.put_active.strike,
            qty: state.put_active.qty,
            sell_point: state.put_active.sell_point,
          };
        }
        const { data } = await axios.post("/api/place-order", body);
        if (data) {
          const { data: order } = data;
          if (order.order_id) {
            fetchOrders();
            fetchPositions();
          }
        }
        return true;
      } catch (e) {
        console.log("ORDER ERROR /////", e);
      }
      return [];
    },
    enabled: false,
  });

  const { mutate: exitOrder, isLoading: isExitingOrder } = useMutation({
    mutationKey: ["exit-orders"],
    mutationFn: async (order_id) => {
      try {
        let body = { order_id };
        const { data } = await axios.post("/api/exit-order", body);
        if (data) {
          const { data: order } = data;
          if (order.order_id) {
            fetchOrders();
            fetchPositions();
          }
        }
        return true;
      } catch (e) {
        console.log("ORDER ERROR /////", e);
      }
      return [];
    },
    enabled: false,
  });

  const { isLoading: isLoggingOut, refetch } = useQuery({
    queryKey: ["logout"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/logout");
        if (data.logout) {
          localStorage.removeItem("has_token");
          history.push("/");
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    enabled: false,
  });

  const { refetch: reloadInstruments } = useQuery({
    queryKey: ["reload-instrumets"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/reload-instruments");
        if (data) {
          console.log(data);
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    enabled: false,
  });

  const { refetch: fetchQuotes } = useQuery({
    queryKey: ["get-quotes"],
    queryFn: async () => {
      const strike_prices = [];
      if (state.call_strike) {
        strike_prices.push(state.call_strike);
      }
      if (state.put_strike) {
        strike_prices.push(state.put_strike);
      }
      if (strike_prices.length === 0) {
        dispatch({
          type: "call_active",
          value: null,
        });
        dispatch({
          type: "put_active",
          value: null,
        });
        return null;
      }
      try {
        const { data } = await axios.get("/api/get-quotes", {
          params: {
            strike_prices,
          },
        });
        if (data) {
          console.log(data);
          if (data.data[`NFO:${state.call_strike}`]) {
            const call_quote = data.data[`NFO:${state.call_strike}`];
            dispatch({
              type: "call_quote",
              value: call_quote,
            });
            if (call_quote && call_quote.instrument_token) {
              dispatch({
                type: "call_active",
                value: {
                  strike: state.call_strike,
                  type: state.call_type,
                  qty: state.call_qty,
                  sell_point: state.call_sell_point,
                  last_price: call_quote.last_price,
                },
              });
            } else {
              dispatch({
                type: "call_active",
                value: null,
              });
            }
          } else {
            dispatch({
              type: "call_active",
              value: null,
            });
          }

          if (data.data[`NFO:${state.put_strike}`]) {
            const put_quote = data.data[`NFO:${state.put_strike}`];

            dispatch({
              type: "put_quote",
              value: put_quote,
            });
            if (put_quote && put_quote.instrument_token) {
              dispatch({
                type: "put_active",
                value: {
                  strike: state.put_strike,
                  type: state.put_type,
                  qty: state.put_qty,
                  sell_point: state.put_sell_point,
                  last_price: put_quote.last_price,
                },
              });
            } else {
              dispatch({
                type: "put_active",
                value: null,
              });
            }
          } else {
            dispatch({
              type: "put_active",
              value: null,
            });
          }
        }
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    enabled: false,
  });

  return (
    <>
      {isLoggingOut && <div>Logging out...</div>}
      <Container fluid>
        <Navbar bg="dark" data-bs-theme="dark">
          <Container fluid>
            <Navbar.Brand href="#home">Trade with Konnect</Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link href="#home">Bank Nifty</Nav.Link>
              <Nav.Link href="#features">Nifty</Nav.Link>
            </Nav>
            <Nav className="me-right">
              <Nav.Link href="#reload" onClick={() => reloadInstruments()}>
                Reload Instruments
              </Nav.Link>
              <Nav.Link href="#logout" onClick={() => refetch()}>
                Logout
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        <Container fluid>
          <Form>
            <Row style={{ padding: "1rem", backgroundColor: "#efefef" }}>
              <Col xs="6" lg="2">
                <Form.Group className="mb-3">
                  <Form.Label className={styles.formLabel}>
                    Call Strike
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="callStrike"
                    style={{ fontSize: ".8rem" }}
                    width={100}
                    value={state.call_strike}
                    onChange={(e) => {
                      dispatch({
                        type: "call_strike",
                        value: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs="6" lg="1">
                <Form.Group className="mb-3">
                  <Form.Label className={styles.formLabel}>Type</Form.Label>
                  <Form.Select
                    size="sm"
                    id="call_type"
                    value={state.call_type}
                    onChange={(e) => {
                      dispatch({
                        type: "call_type",
                        value: e.target.value,
                      });
                    }}
                  >
                    <option value="Market">Market</option>
                    <option value="Limit">Limit</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="6" lg="1">
                <Form.Group className="mb-3">
                  <Form.Label className={styles.formLabel}>Qty</Form.Label>
                  <Form.Select
                    size="sm"
                    id="call_qty"
                    value={state.call_qty}
                    onChange={(e) => {
                      dispatch({
                        type: "call_qty",
                        value: Number.parseInt(e.target.value),
                      });
                    }}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="125">125</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="6" lg="1">
                <Form.Group className="mb-3">
                  <Form.Label className={styles.formLabel}>
                    Sell Point
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    id="call_sell_point"
                    value={state.call_sell_point}
                    onChange={(e) => {
                      dispatch({
                        type: "call_sell_point",
                        value: Number.parseInt(e.target.value),
                      });
                    }}
                  >
                    <option value="2">+2</option>
                    <option value="3">+3</option>
                    <option value="4">+4</option>
                    <option value="5">+5</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="6" lg="2">
                <Form.Group className="mb-3">
                  <Form.Label className={styles.formLabel}>
                    Put Strike
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="put_strike"
                    style={{ fontSize: ".8rem" }}
                    value={state.put_strike}
                    onChange={(e) => {
                      dispatch({
                        type: "put_strike",
                        value: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs="6" lg="1">
                <Form.Group className="mb-3">
                  <Form.Label className={styles.formLabel}>Type</Form.Label>
                  <Form.Select
                    size="sm"
                    id="put_type"
                    value={state.put_type}
                    onChange={(e) => {
                      dispatch({
                        type: "put_type",
                        value: e.target.value,
                      });
                    }}
                  >
                    <option value="Market">Market</option>
                    <option value="Limit">Limit</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="6" lg="1">
                <Form.Group className="mb-3">
                  <Form.Label className={styles.formLabel}>Qty</Form.Label>
                  <Form.Select
                    size="sm"
                    id="put_qty"
                    value={state.put_qty}
                    onChange={(e) => {
                      dispatch({
                        type: "put_qty",
                        value: Number.parseInt(e.target.value),
                      });
                    }}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="125">125</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="6" lg="1">
                <Form.Group className="mb-3">
                  <Form.Label className={styles.formLabel}>
                    Sell Point
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    id="put_sell_point"
                    value={state.put_sell_point}
                    onChange={(e) => {
                      dispatch({
                        type: "put_sell_point",
                        value: Number.parseInt(e.target.value),
                      });
                    }}
                  >
                    <option value="2">+2</option>
                    <option value="3">+3</option>
                    <option value="4">+4</option>
                    <option value="5">+5</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="6" lg="1">
                <Button
                  type="button"
                  size="sm"
                  className={styles.formButton}
                  style={{ marginTop: "1.75rem", marginLeft: "2rem" }}
                  onClick={() => fetchQuotes()}
                >
                  Get Quote
                </Button>
              </Col>
            </Row>
            <Row style={{ padding: "2rem 1rem 0" }}>
              <Col
                xs="12"
                md="4"
                lg="3"
                className={state.call_active ? styles.show : styles.hidden}
              >
                <Row>
                  <Col xs="12">
                    <strong>{state.call_active?.strike} LTP:</strong>{" "}
                    {state.call_active?.last_price}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    (<strong>Type: </strong>
                    {state.call_active?.type} - <strong>Qty:</strong>{" "}
                    {state.call_active?.qty} - <strong>Sell Pt:</strong> +
                    {state.call_active?.sell_point})
                  </Col>
                </Row>
              </Col>
              <Col
                xs="12"
                md="4"
                lg="2"
                className={state.call_active ? styles.show : styles.hidden}
              >
                <Button
                  type="button"
                  size="sm"
                  className={styles.formButton}
                  style={{ marginLeft: "1rem" }}
                  onClick={() => placeOrder("CALL")}
                  disabled={isPlacingOrder}
                >
                  &uarr; Buy Call
                </Button>
              </Col>

              <Col xs="12" lg="1">
                &nbsp;
              </Col>

              <Col
                xs="12"
                md="4"
                lg="3"
                className={state.put_active ? styles.show : styles.hidden}
              >
                <Row>
                  <Col xs="12">
                    <strong>{state.put_active?.strike} LTP:</strong>{" "}
                    {state.put_active?.last_price}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    (<strong>Type: </strong>
                    {state.put_active?.type} - <strong>Qty:</strong>{" "}
                    {state.put_active?.qty} - <strong>Sell Pt:</strong> +
                    {state.put_active?.sell_point})
                  </Col>
                </Row>
              </Col>
              <Col
                xs="12"
                lg="2"
                className={state.put_active ? styles.show : styles.hidden}
              >
                <Button
                  type="submit"
                  size="sm"
                  className={styles.formButton}
                  style={{ marginLeft: "1rem" }}
                  onClick={() => placeOrder("PUT")}
                  disabled={isPlacingOrder}
                >
                  &darr; Buy Put
                </Button>
              </Col>
            </Row>
            <hr />
          </Form>
          <Row>
            <Col md="6" lg="5">
              <h2 style={{ fontSize: "1.25rem" }}>Orders</h2>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Instrument</th>
                    <th>Type</th>
                    <th>Qty.</th>
                    <th>Avg.</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.orders &&
                    state.orders?.length > 0 &&
                    state.orders?.map((item) => (
                      <tr key={`order-${item.order_id}`}>
                        <td>{item.tradingsymbol}</td>
                        <td>{item.transaction_type}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {item.status === "COMPLETE" && (
                            <span>{item.average_price}</span>
                          )}
                          {(item.status === "REJECTED" ||
                            item.status === "CANCELLED") && (
                            <span>{item.price}</span>
                          )}
                        </td>
                        <td>
                          <span
                            className={
                              item.average_price > 0
                                ? styles.buyColor
                                : styles.sellColor
                            }
                          >
                            {item.status}
                          </span>
                        </td>
                        <td>
                          &nbsp;
                          {item.status === "OPEN" && (
                            <Button
                              variant="link"
                              size="sm"
                              style={{ padding: 0 }}
                              onClick={() => exitOrder(item.order_id)}
                              disabled={isExitingOrder}
                            >
                              Exit
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  {state.orders?.length <= 0 && (
                    <tr>
                      <td colSpan={6}>No Orders Available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            <Col md="1" lg="2"></Col>
            <Col md="6" lg="5">
              <Row>
                <Col md="7" lg="8">
                  <h2 style={{ fontSize: "1.25rem" }}>Positions</h2>
                </Col>
                <Col md="5" lg="4">
                  <strong>Total P&L:</strong> +12,479
                </Col>
              </Row>

              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Instrument</th>
                    <th>Type</th>
                    <th>Qty.</th>
                    <th>Avg.</th>
                    <th>P&L</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {state.positions?.net &&
                    state.positions?.net.map((item) => (
                      <tr key={`position-${item.instrument_token}`}>
                        <td>{item.tradingsymbol}</td>
                        <td>{item.product}</td>
                        <td>{item.quantity}</td>
                        <td>{item.average_price}</td>
                        <td>
                          <span
                            className={
                              item.unrealised > 0
                                ? styles.buyColor
                                : styles.sellColor
                            }
                          >
                            {item.unrealised}
                          </span>
                        </td>
                        {/* <td>
                          <Button
                            variant="link"
                            size="sm"
                            style={{ padding: 0 }}
                          >
                            Cancel
                          </Button>
                        </td> */}
                      </tr>
                    ))}
                  {state.positions?.day &&
                    state.positions?.day.map((item) => (
                      <tr key={`position-${item.instrument_token}`}>
                        <td>{item.tradingsymbol}</td>
                        <td>{item.product}</td>
                        <td>{item.quantity}</td>
                        <td>{item.average_price}</td>
                        <td>
                          <span
                            className={
                              item.unrealised > 0
                                ? styles.buyColor
                                : styles.sellColor
                            }
                          >
                            {item.unrealised}
                          </span>
                        </td>
                        {/* <td>
                          <Button
                            variant="link"
                            size="sm"
                            style={{ margin: 0 }}
                          >
                            Cancel
                          </Button>
                        </td> */}
                      </tr>
                    ))}
                  {!state.positions && (
                    <tr>
                      <td colSpan={6}>No Positions Available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default BankNifty;
