import React, { useReducer, useEffect, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Header from "../shared/Header";
import Orders from "../shared/Orders";
import Positions from "../shared/Positions";
import reducer from "./reducer";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import Instruments from "../shared/Instruments";

function OptionsV2() {
  const history = useHistory();
  let fetchQuoteTimer = useRef(null);
  console.log(JSON.parse(localStorage.getItem("instruments")));
  const [state, dispatch] = useReducer(reducer, {
    positions: [],
    positions_pl: 0,
    orders: [],
    polling_for_quotes: true,
    new_instrument: "",
    instruments: JSON.parse(localStorage.getItem("instruments")) || [],
    instrument_quotes: [],
    positions_each_pl: [],
  });

  console.log("STATE ", state);

  const { refetch: fetchPositions } = useQuery({
    queryKey: ["get-positions"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/get-positions");
        if (data) {
          const { data: positions } = data;
          dispatch({
            type: "positions",
            value: positions,
          });

          if (positions.net && positions.net.length > 0) {
            const pnls = [];
            positions.net.forEach((pos) => {
              pnls[pos.tradingsymbol] = Number(pos.pnl).toFixed(2);
            });
            dispatch({
              type: "positions_each_pl",
              value: pnls,
            });
            dispatch({
              type: "positions_pl",
              value: positions.net.reduce((total, item) => {
                return total + item.unrealised;
              }, 0),
            });
          }
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
  });

  const { refetch: fetchOrders } = useQuery({
    queryKey: ["get-orders"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/get-orders");
        if (data) {
          const { data: orders } = data;
          dispatch({
            type: "orders",
            value: orders.reverse(),
          });
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
  });

  const { mutate: placeOrder, isLoading: isPlacingOrder } = useMutation({
    mutationKey: ["place-orders"],
    mutationFn: async ({ instrument, price }) => {
      console.log("place order", { instrument, price });
      try {
        let body = null;

        body = {
          tradingsymbol: instrument.strike,
          qty: instrument.qty,
          sell_point: instrument.sell_point,
          price: price,
        };
        if (!price) {
          return false;
        }
        const { data } = await axios.post("/api/place-order", body);
        if (data) {
          const { data: order } = data;
          if (order && order.order_id) {
            fetchOrders();
            fetchPositions();
          } else if (data.error) {
            toast(data?.error?.message);
          }
        }
        return true;
      } catch (e) {
        console.log("ORDER ERROR /////", e);
        toast(e);
      }
      return [];
    },
    enabled: false,
  });

  const { mutate: exitOrder, isLoading: isExitingOrder } = useMutation({
    mutationKey: ["exit-orders"],
    mutationFn: async (order) => {
      try {
        let body = { order_id: order.order_id };
        // TODO: need to make sure current active items in this trade sylmbol only
        if (order.tradingsymbol.endsWith("CE")) {
          body.price = state.call_active?.last_price;
        } else {
          body.price = state.put_active?.last_price;
        }
        const { data } = await axios.post("/api/exit-order", body);
        if (data) {
          const { data: order } = data;
          if (order.order_id) {
            fetchOrders();
            fetchPositions();
          }
        }
        return true;
      } catch (e) {
        console.log("ORDER ERROR /////", e);
      }
      return [];
    },
    enabled: false,
  });

  const { isLoading: isLoggingOut, refetch: logout } = useQuery({
    queryKey: ["logout"],
    queryFn: async () => {
      try {
        const { data } = await axios.get("/api/logout");
        if (data.logout) {
          localStorage.removeItem("has_token");
          history.push("/");
        }
        return data;
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    enabled: false,
  });

  useEffect(() => {
    if (state.instruments && state.instruments?.length > 0) {
      clearTimeout(fetchQuoteTimer);
      fetchQuotesv2();
    }
  }, [state.instruments]);

  const { refetch: fetchQuotesv2 } = useQuery({
    queryKey: ["get-quotesv2"],
    queryFn: async () => {
      if (state.instruments && state.instruments.length) {
        const strike_prices = state.instruments.map((item) => item.strike);

        try {
          const { data } = await axios.get("/api/get-quotesv2", {
            params: {
              strike_prices,
            },
          });
          if (data) {
            console.log(data);
            const quotes = data.data;
            const instrumentQuotes = {};

            strike_prices.forEach((strike) => {
              if (quotes[`NFO:${strike}`]) {
                instrumentQuotes[strike] = quotes[`NFO:${strike}`];
              }
            });

            console.log({ instrumentQuotes });

            if (instrumentQuotes) {
              dispatch({
                type: "instrument_quotes",
                value: instrumentQuotes,
              });
            }

            // now we need to refetch
            console.log("polling ", state.polling_for_quotes);
            if (state.polling_for_quotes) {
              fetchQuoteTimer = setTimeout(() => {
                fetchQuotesv2();
              }, 1000);
            }
          }
          return data;
        } catch (e) {
          console.log(e);
        }
      }
    },
    enabled: true,
  });

  return (
    <>
      {isLoggingOut && <div>Logging out...</div>}
      <Container fluid>
        <Header logout={logout} />

        <Container fluid>
          <Instruments
            state={state}
            dispatch={dispatch}
            fetchQuotesv2={fetchQuotesv2}
            placeOrder={placeOrder}
          />
          <Row>
            <Col md="6" lg="5">
              <Orders
                state={state}
                exitOrder={exitOrder}
                isExitingOrder={isExitingOrder}
              />
            </Col>
            <Col md="1" lg="1"></Col>
            <Col md="6" lg="5">
              <Positions state={state} />
            </Col>
          </Row>
        </Container>
      </Container>
      <ToastContainer />
    </>
  );
}

export default OptionsV2;
